<template>
  <div class="chat-container">
    <div class="chat-window" ref="chatWindow">
      <ChatBubble
        v-for="(msg, index) in messages"
        :key="index"
        :message="msg.text"
        :sender="msg.sender"
        :isMarkdown="msg.sender === 'assistant'"
      />
    </div>
    <div class="chat-input">
      <input
        v-model="newMessage"
        @keyup.enter="sendMessage"
        placeholder="Enter a message..."
        :disabled="loading"
      />
      <button @click="sendMessage" :disabled="loading">
        {{ loading ? "..." : "Send" }}
      </button>
    </div>
  </div>
</template>

<script>
import ChatBubble from "./ChatBubble.vue";
import * as Utils from "@/utils/Utils";
import { SEND_CHAT_AI_SERVER } from "@/store/actionsType";

import { marked } from "marked";

export default {
  components: { ChatBubble },
  data() {
    return {
      newMessage: "",
      messages: [],
      loading: false,
    };
  },
  created() {
    this.loadMessages();
  },
  methods: {
    loadMessages() {
      const savedMessages = localStorage.getItem("chatHistory");
      if (savedMessages) {
        this.messages = JSON.parse(savedMessages);
      } else {
        this.messages = [{ text: "Hello! How can I help?", sender: "assistant" }];
      }
    },
    saveMessages() {
      localStorage.setItem("chatHistory", JSON.stringify(this.messages));
    },
    clearChatMessages() {
      this.messages = [{ text: "Hello! How can I help?", sender: "assistant" }];
    },
    async sendMessage() {
      if (this.newMessage.trim() === "") return;

      this.messages.push({ text: this.newMessage, sender: "user" });
      this.saveMessages();
      this.$nextTick(() => {
        this.$refs.chatWindow.scrollTop = this.$refs.chatWindow.scrollHeight;
      });

      // const userMessage = this.newMessage;
      this.newMessage = "";
      this.loading = true;
      const maxHistory = 10;

      const requestModal = {
        Prompt: this.messages.slice(-maxHistory).map((msg) => ({
        // Prompt: this.messages.map((msg) => ({
          Role: msg.sender === "user" ? "user" : "assistant",
          Content: msg.text,
        })),
      };
      marked.setOptions({
        headerIds: false,
        mangle: false,
        smartLists: true
      });
      this.$store
        .dispatch(SEND_CHAT_AI_SERVER, requestModal)
        .then((response) => {
          if (response.status === "succeeded" || response.status === "active") {
            // const botReply = response.message.replace(/^assistant:\s*/i, "");
            const botReply = marked.parse(response.message.replace(/^assistant: \s*/i, ""));
            this.messages.push({ text: botReply, sender: "assistant" });
            this.saveMessages();
          } else {
            Utils.toastError(response.status);
          }
        })
        .catch((error) => {
          Utils.toastError(error);
        })
        .finally(() => {
          this.loading = false;
          this.$nextTick(() => {
            this.$refs.chatWindow.scrollTop = this.$refs.chatWindow.scrollHeight;
          });
        });
    },
  },
};
</script>

<style scoped>
.chat-container {
  width: 320px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: white;
}

.chat-window {
  height: 360px;
  overflow-y: auto;
  padding: 60px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
}
.chat-input {
  display: flex;
  border-top: 1px solid #ccc;
  padding: 8px;
  background: white;
}
.chat-input input {
  flex: 1;
  border: none;
  padding: 10px;
  border-radius: 5px;
  outline: none;
}
.chat-input button {
  background: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  margin-left: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.chat-input button:disabled {
  background: #ccc;
  cursor: not-allowed;
}
@media only screen and (orientation: landscape){
  .chat-window {
    height: 60vh;
    max-height: 360px;
  }
}
</style>
