<template>
  <div class="chat-widget">
    <div v-if="!isChatOpen" class="chat-button" @click="toggleChat">
      <img src="/assets/img/Chatbot.png" alt="">
    </div>
    <div :class="['chat-box', { 'open': isChatOpen, 'close': !isChatOpen }]" v-show="isChatVisible">
      <div class="chat-box-header">
        <div>
          <img src="/assets/img/Chatbot.png" alt="">
          <span style="padding-left: 4px; font-size: 14px; color: white;">
            CyrusPay AI
          </span>
        </div>
        <div class="d-flex">
          <button class="minimize-btn" @click="minimizeChat">
            <svg width="20" height="20" viewBox="0 0 24 24" fill="white">
            <rect x="5" y="11" width="14" height="2" />
          </svg>
          </button>
          <button class="close-btn" @click="closeChat">✖</button>
        </div>
      </div>
      <ChatBox ref="chatBox" />
    </div>
  </div>
</template>

<script>
import ChatBox from './ChatBox.vue';

export default {
  components: { ChatBox },
  data() {
    return {
      isChatOpen: false,
      isChatVisible: false
    };
  },
  methods: {
    toggleChat() {
      if (this.isChatOpen) {
        setTimeout(() => {
          this.isChatVisible = false;
        }, 400);
      } else {
        this.isChatVisible = true;
      }
      this.isChatOpen = !this.isChatOpen;
    },
    minimizeChat() {
      this.isChatOpen = false;
    },
    closeChat() {
    this.isChatOpen = false;
    this.isChatVisible = false;
    localStorage.removeItem("chatHistory");
    if (this.$refs.chatBox) {
      this.$refs.chatBox.clearChatMessages();
    }
  }
  }
};
</script>

<style scoped>
.chat-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.chat-button {
  width: 60px;
  height: 60px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chat-button img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  object-fit: contain;
  border: 1px solid #f1f1f1;
}

.chat-box {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 320px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  background: white;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #ccc;
}
.chat-box.open {
  animation: chatBoxOpen 0.3s ease-in forwards;
}

@keyframes chatBoxOpen {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  50% {
    width: 320px;
    height: 250px;
    opacity: 0.5;
  }
  75% {
    width: 320px;
    height: 350px;
    opacity: 1;

  }
  100% {
    width: 320px;
    height: auto;
    opacity: 1;
  }
}

.chat-box.close {
  animation: chatBoxClose 0.3s ease-out forwards;
}

@keyframes chatBoxClose {
  0% {
    width: 320px;
    height: auto;
    opacity: 1;
  }
  50% {
    width: 320px;
    height: 350px;
    opacity: 1;
  }
  75% {
    width: 320px;
    height: 250px;
    opacity: 0.5;
  }
  100% {
    width: 0;
    height: 0;
    opacity: 0;
  }
}

.minimize-btn ,.close-btn {
  color: white;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  font-size: 19px;
}
.minimize-btn:hover ,.close-btn:hover {
  border-radius: 20px;
  background-color: #f5f5f566;
}

.chat-box-header {
  position: absolute;
  top: 0;
  display: flex;
  padding: 10px;
  background-color: rgb(9, 181, 133);
  width: 100%;
  box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  align-items: center;
}

.chat-box-header img {
  width: 30px;
  height: 30px;
  border-radius: 50px;
}
.close-btn:focus,.minimize-btn:focus {
  outline:none;
}
</style>
