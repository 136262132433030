<template>
  <div :class="['chat-bubble', sender]">
    <span v-if="!isMarkdown">{{ message }}</span>
    <span v-else v-html="message"></span>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
    sender: String,
    isMarkdown: Boolean
  }
};
</script>


<style scoped>
.chat-bubble {
  max-width: 70%;
  padding: 10px 15px;
  border-radius: 18px;
  margin: 5px 0;
  font-size: 14px;
  word-wrap: break-word;
}
.user {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
  border-bottom-right-radius: 5px;
  text-align: left;
}
.assistant {
  background-color: #f1f1f1;
  color: black;
  align-self: flex-start;
  border-bottom-left-radius: 5px;
  text-align: left;
}
.chat-bubble p {
  margin: 0;
}

.chat-bubble.assistant:has(ol) {
  padding-left: 30px;
}
.chat-bubble.assistant:has(ul) {
  padding-left: 30px;
}
</style>
